<template>
  <div>
    <h2>Other</h2>
    <div class="content">
      This is everything else i have not completed or abandoned
      <ul>
        <li>This page</li>
        <li>How to configure this website (6 steps)</li>
        <li>To do list</li>
        <li>Metronome</li>
        <li>Code face</li>
        <li>Insertion Sort</li>
        <li>Solitaire</li>
      </ul>
    </div>
  </div>
</template>
